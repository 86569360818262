// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  // Public params
  forgotPasswordForm: UntypedFormGroup;
  loading = false;
  errors: any = [];

  // Token przekazany z wiadomości Email
  tokenFromEmail: any;

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param authService
   * @param authNoticeService
   * @param translate
   * @param router
   * @param fb
   * @param cdr
   */
  constructor(
    private authService: AuthService,
    public authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.initRegistrationForm();
    const decode = this.activatedRoute.queryParams.subscribe((val) => {
      this.tokenFromEmail = val.token;
    });
    // Jezeli token wygasł - wyświetl komunikat i przekieruj
    this.authService
      .validatePasswordResetToken({
        passwordResetToken: this.tokenFromEmail,
      })
      .subscribe((res) => {
        if (!res.success) {
          this.authNoticeService.setNotice(
            'Link stracił wazność. Spróbuj jeszcze raz.',
            'danger'
          );
          setTimeout(() => {
            this.router.navigateByUrl('/auth/forgot-password');
          }, 1000);
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegistrationForm() {
    this.forgotPasswordForm = new UntypedFormGroup({
      userPassword: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(?=^.{8,}$)((?=.*d)|)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'
          ),
        ])
      ),
      userConfirmPassword: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(?=^.{8,}$)((?=.*d)|)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'
          ),
        ])
      ),
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.forgotPasswordForm.controls;
    /** check form */
    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;
    const password = controls.userPassword.value;
    console.log(this.tokenFromEmail);
    this.authService
      .resetPassword({
        password,
        passwordResetToken: this.tokenFromEmail,
      })
      .pipe(
        tap((response) => {
          if (response) {
            if (!response.success) {
              this.authNoticeService.setNotice(
                'Link stracił wazność. Spróbuj jeszcze raz.',
                'danger'
              );
              setTimeout(() => {
                this.router.navigateByUrl('/auth/forgot-password');
              }, 1000);
            } else {
              this.authNoticeService.setNotice(
                'Hasło zostało zresetowane. Możesz się zalogować.',
                'success'
              );
              setTimeout(() => {
                this.router.navigateByUrl('/auth/login');
              }, 1000);
            }
          } else {
            this.authNoticeService.setNotice('Wystąpił błąd.', 'danger');
            setTimeout(() => {
              this.router.navigateByUrl('/auth/forgot-password');
            }, 1000);
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
