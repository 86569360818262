// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep ngb-tabset > .nav-tabs {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/layout/quick-panel/quick-panel.component.scss"],"names":[],"mappings":"AAEE;EACC,aAAA;AADH","sourcesContent":[":host {\r\n\t::ng-deep {\r\n\t\tngb-tabset > .nav-tabs {\r\n\t\t\tdisplay: none;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
