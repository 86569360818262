import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Datatable
// General widgets
import { Widget4Component } from './widget4/widget4.component';
import { Widget26Component } from './widget26/widget26.component';

@NgModule({
  declarations: [
    // Widgets
    Widget26Component,
    Widget4Component,
  ],
  exports: [
    // Widgets
    Widget26Component,
    Widget4Component,
  ],
  imports: [CommonModule],
})
export class WidgetModule {}
