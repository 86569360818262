import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// Angular
import { Injectable } from '@angular/core';
// Serwisy
import { ToastService } from './toast.service';
import { PosSensorsService } from './pos-sensors.service';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class TableService {
  // nazwa arkusza Excel
  sheetName: any;
  // loader dla pola importu pliku excel
  loading = false;

  constructor(
    private toastService: ToastService,
    private posSensorsService: PosSensorsService
  ) {}

  // EXPORT

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // public exportAsExcelFile(json, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { Informacje: worksheet },
      SheetNames: ['Informacje'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  /**
   * Funkcja odpowiadająca za importowanie pliku
   * @param ev
   * @param formGroup na potrzeby przypisywania wartości do pola select
   * @param storageObject obiekt w storage do którego przypisujemy wartości
   * @param sfidFilters
   */

  public importExcelFile(ev, formGroup, storageObject, sfidFilters) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: 'binary',
      });

      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        this.sheetName = name;
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      jsonData[this.sheetName].forEach((column) => {
        column = JSON.parse(
          JSON.stringify(column)
            .replace(/"\s+|\s+"/g, '"')
            .toLowerCase()
        );
        // jezeli jest kolumna sfid to utworz filtr
        if (column.sfid) {
          column.sfid = JSON.parse('"' + column.sfid + '"');
          // przerabianie na elementy tablicy, aby nie było problemów z filtrowaniem
          // column.sfid = JSON.parse('[' + column.sfid + ']');
          sfidFilters.push(column.sfid);
        }
        // w przeciwnym wypadku schowaj loader
        else {
          this.loading = false;
        }
      });
      // wyświetlanie komunikatów
      if (sfidFilters.length < 1) {
        this.loading = false;
        this.toastService.displayToast('Brak kolumny o nazwie sfid');
      } else {
        this.toastService.displayToast('Pomyślnie zaimportowano filtry');
      }
      // Zapis wartosci do localstorage i do pola wyboru w tabeli
      storageObject.sfid = sfidFilters;
      sfidFilters = sfidFilters;
      formGroup.get('sfidFilter').setValue(storageObject.sfid);
    };
    reader.onprogress = (event) => {
      this.loading = false;
    };
    this.loading = true;
    try {
      // zczytywanie pliku
      reader.readAsBinaryString(file);
    } catch {
      this.loading = false;
    }
  }
  /**
   * Funkcja odpowiadająca za importowanie pliku w celu automatycznej aktualizacji punktow pos
   * @param ev
   * **/

  importExcelToUpdatePos(ev) {
    const file = ev.target.files[0];
    let formData = new FormData();
    formData.append('file', file);

    this.posSensorsService.updatePosFromFile(formData).subscribe((res) => {});
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Sprawdzanie czy zostały zaznaczone wszystkie wiersze
   */

  isAllSelected(selection, dataSource) {
    const numSelected = selection.selected.length;
    const numRows = dataSource.filteredData.length;
    return numSelected == numRows;
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Funkcjonalność głównego włącznika
   */

  masterToggle(selection, dataSource) {
    this.isAllSelected(selection, dataSource)
      ? selection.clear()
      : dataSource.filteredData.forEach((row) => selection.select(row));
  }
}
