// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host [ngbdropdowntoggle]::after {
  display: inline-block !important;
}

input[type=radio],
input[type=checkbox] {
  margin: 0 9px 0 25px;
}

label {
  margin: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/layout/column-visibility-menu/column-visibility-menu.component.scss"],"names":[],"mappings":"AAGC;EACC,gCAAA;AAFF;;AAMA;;EAEC,oBAAA;AAHD;;AAMA;EACC,WAAA;AAHD","sourcesContent":[":host {\r\n\r\n\t// enable ng-boostrap dropdowm arrow\r\n\t[ngbdropdowntoggle]::after {\r\n\t\tdisplay: inline-block !important;\r\n\t}\r\n}\r\n\r\ninput[type=radio],\r\ninput[type=checkbox] {\r\n\tmargin: 0 9px 0 25px;\r\n}\r\n\r\nlabel {\r\n\tmargin: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
