// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .kt-header__topbar > :last-child:not([role=tooltip]) .kt-header__topbar-item {
  margin-right: 0 !important;
}
:host ::ng-deep .kt-header__topbar .kt-header__topbar-item {
  margin-right: 0.36rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/header/topbar/topbar.component.scss"],"names":[],"mappings":"AAKI;EACC,0BAAA;AAJL;AAOG;EACC,gCAAA;AALJ","sourcesContent":[":host {\r\n\t::ng-deep {\r\n\t\t// override default version style\r\n\t\t.kt-header__topbar {\r\n\t\t\t> :last-child:not([role=\"tooltip\"]) {\r\n\t\t\t\t.kt-header__topbar-item {\r\n\t\t\t\t\tmargin-right: 0 !important;\r\n\t\t\t\t}\r\n\t\t\t}\r\n\t\t\t.kt-header__topbar-item {\r\n\t\t\t\tmargin-right: 0.36rem !important;\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
