export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          alignment: 'left',
          page: '/dashboard',
          // translate: 'MENU.DASHBOARD',
        },
        {
          title: 'POS',
          root: true,
          alignment: 'left',
          page: '/pos',
        },
        {
          title: 'Sensory',
          root: true,
          alignment: 'left',
          page: '/sensors',
        },
        {
          title: 'Diagnostyka',
          root: true,
          alignment: 'left',
          page: '/diagnostics',
        },
        {
          title: 'KR',
          root: true,
          alignment: 'left',
          page: '/kr',
        },
        {
          title: 'RKS',
          root: true,
          alignment: 'left',
          page: '/rks',
        },
        {
          title: 'Zgłoszenia',
          root: true,
          alignment: 'left',
          page: '/ticket',
          ticketBadge: true,
        },
        {
          title: 'Instrukcja',
          root: true,
          alignment: 'left',
          page: '/instructions',
        },
      ],
    },
    aside: {
      self: {},
      items: [
        { section: 'MENU' },
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'POS',
          root: true,
          icon: 'flaticon2-list-2',
          page: '/pos',
        },
        {
          title: 'Sensory',
          root: true,
          icon: 'flaticon2-analytics-1',
          page: '/sensors',
        },
        {
          title: 'Diagnostyka',
          root: true,
          icon: 'flaticon2-gear',
          page: '/diagnostics',
        },
        {
          title: 'KR',
          root: true,
          icon: 'flaticon2-avatar',
          page: '/kr',
        },
        {
          title: 'RKS',
          root: true,
          icon: 'flaticon2-avatar',
          page: '/rks',
        },
        {
          title: 'Zgłoszenia',
          icon: 'flaticon2-list-2',
          root: true,
          page: '/ticket',
          ticketBadge: true,
        },
        {
          title: 'Instrukcja',
          root: true,
          icon: 'flaticon-file-1',
          page: '/instructions',
        },
        // {
        //   title: 'Mój profil',
        //   root: true,
        //   icon: 'flaticon2-user-outline-symbol',
        //   page: '/user'
        // },
        // { section: 'Instrukcje' },
        // {
        //   title: 'Instrukcja montażu PDF',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-file',

        //   page: '/instrukcja_demontazu.pdf',
        // },
        // {
        //   root: false,
        //   title: 'Instrukcja demontażu PDF',
        //   bullet: 'dot',
        //   icon: 'flaticon2-file',
        //   page: '/instrukcja_demontazu.pdf'
        // }
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
