import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Ticket,
  TicketData,
  TicketTypesList,
} from 'app/views/pages/ticket/ticket.model';
import { AuthService } from 'app/core/auth';
@Injectable()
export class TicketService {
  url = environment.url;

  constructor(
    private http: HttpClient,
    private matSnackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  // Zwraca listę zgłoszen
  getTicketList() {
    return this.http.get<{ data: any[] }>(`${this.url}/ticket/list`);
  }

  // Zwraca szczegóły danego zgłoszenia
  getTicketDetails(
    ticketId: string
  ): Observable<{ data: TicketData; success: boolean }> {
    return this.http.get<{ data: TicketData; success: boolean }>(
      `${this.url}/ticket/details/${ticketId}`
    );
  }

  /**
   * Funkcja odpowiedzialna za utworzenie zgłoszenia
   * @since 22.01.2024
   * @param ticket
   * @returns
   */
  createTicket(ticket: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}/ticket/create-ticket`, ticket).pipe(
      tap((res) => {
        if (!res.success) {
          this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
            duration: 5000,
          });
        } else {
          this.matSnackBar.open('Zgłoszenie zostało utworzone', 'Zamknij', {
            duration: 5000,
          });
        }
      })
    );
  }

  /**
   * Funkcja odpowiedzialna za usunięcie zgłoszenia
   * @since 22.01.2024
   */
  deleteTicket(ticketId: string): Observable<any> {
    return this.http
      .get<any>(`${this.url}/ticket/delete-ticket/${ticketId}`, {})
      .pipe(
        tap((res) => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił bład', 'Zamknij', {
              duration: 5000,
            });
          } else {
            this.matSnackBar.open('Zgłoszenie zostało usunięte', 'Zamknij', {
              duration: 5000,
            });
          }
        })
      );
  }

  /**
   * Funkcja weryfikujaca czy możliwe jest przeprowadzenie operacji na zgłoszeniu
   * @since 22.01.2024
   * @param company
   * @returns
   */
  canPerformAnActionRestrictedToACompany(company: string): Observable<boolean> {
    return this.authService.getRole().pipe(
      map((roles) => {
        if (company === 'Yoberi' && roles.includes('operator')) {
          return true;
        } else if (company === 'GPP' && !roles.includes('operator')) {
          return true;
        }
        return false;
      })
    );
  }

  /**
   * Funkcja aktualizujaca zgłoszenie
   * @since 22.01.2024
   * @param action
   * @param ticketId
   * @returns
   */
  updateTicket(ticketId: string, ticketData?: FormData) {
    return this.http
      .post<any>(`${this.url}/ticket/update-ticket/${ticketId}`, ticketData)
      .pipe(
        tap((res) => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
              duration: 5000,
            });
          } else {
            this.matSnackBar.open('Pomyślnie zaktualizowano', 'Zamknij', {
              duration: 5000,
            });
          }
        })
      );
  }

  // Umożliwia utworzenie komentarza do zgłoszenia
  createTicketComment(ticketFormData: FormData): Observable<any> {
    return this.http
      .post<any>(`${this.url}/ticket/create-ticket-comment`, ticketFormData)
      .pipe(
        tap((res) => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
              duration: 5000,
            });
          } else {
            this.matSnackBar.open('Utworzono komentarz', 'Zamknij', {
              duration: 5000,
            });
          }
        })
      );
  }

  /**
   * Możliwość usuwania komentarza do zgłoszenia
   * @since 19.01.2024
   * @param commentId
   * @returns
   */
  deleteTicketComment(commentId: string) {
    return this.http
      .get<any>(`${this.url}/ticket/delete-ticket-comment/${commentId}`, {})
      .pipe(
        tap((res) => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił bład', 'Zamknij', {
              duration: 5000,
            });
          } else {
            this.matSnackBar.open('Komentarz został usunięty', 'Zamknij', {
              duration: 5000,
            });
          }
        })
      );
  }

  // Zwraca listę statusów funkcjonujących w systemie
  ticketTypesList(): Observable<TicketTypesList> {
    return this.http.get<TicketTypesList>(`${this.url}/ticket/types-list`);
  }

  ticketPosList() {
    return this.http.get<{
      data: {
        idaps: string;
        sfid: string;
      }[];
      success: boolean;
    }>(`${this.url}/ticket/pos-list`);
  }
}
