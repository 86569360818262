// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.brand-btn {
  margin-bottom: 20px;
}

.sensors,
.actions {
  visibility: hidden;
  opacity: 0;
}

.sensors_details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0 5px 0;
}

.mat-select {
  height: 24px !important;
  padding: 2px 5px !important;
}

.mat-column-actions {
  flex: 0 0 7%;
}

.report {
  margin-left: 4px;
}

.buttons button {
  border: none;
  background-color: transparent;
  padding: 0 2px;
}

.mat-column-select {
  overflow: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/pos/list/pos.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AAGA;EACE,mBAAA;AAAF;;AAGA;;EAEE,kBAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,oBAAA;AAAF;;AAIA;EACE,uBAAA;EACA,2BAAA;AADF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAOA;EACE,YAAA;EACA,6BAAA;EACA,cAAA;AAJF;;AAOA;EACE,iBAAA;AAJF","sourcesContent":[".mat-form-field {\n  font-size: 14px;\n  width: 100%;\n}\n\n\n.brand-btn {\n  margin-bottom: 20px;\n}\n\n.sensors,\n.actions {\n  visibility: hidden;\n  opacity: 0;\n}\n\n.sensors_details {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  padding: 8px 0 5px 0;\n}\n\n\n.mat-select {\n  height: 24px !important;\n  padding: 2px 5px !important;\n}\n\n// AKCJE\n.mat-column-actions {\n  flex: 0 0 7%;\n}\n\n.report {\n  margin-left: 4px;\n}\n\n\n\n.buttons button {\n  border: none;\n  background-color: transparent;\n  padding: 0 2px;\n}\n\n.mat-column-select {\n  overflow: initial;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
