// Angular
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';

import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PosSensorsService } from '../../../../services/pos-sensors.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

moment.locale('pl');

@Component({
  selector: 'kt-send-email',
  templateUrl: './sendEmail.component.html',
  styleUrls: ['./sendEmail.component.scss'],
})
export class SendEmailComponent implements OnInit, OnDestroy {
  sendEmailForm: UntypedFormGroup;
  errors: any = [];
  // id i adres przekazywane przy wywoływaniu modala
  idApsArray: any;

  // Treść wiadomości
  title: any = '';
  body: any = '';

  // Wywoływanie spinnera jezeli dane nie są jeszcze wczytane
  loading: any;

  constructor(
    public activeModal: NgbActiveModal,
    private posSensorsService: PosSensorsService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.initRegistrationForm();
    console.log(this.idApsArray);
  }

  initRegistrationForm() {
    this.sendEmailForm = this.fb.group({
      title: ['[Ważne] Prośba o zresetowanie licznika klientów'],
      body: new FormControl(
        `<strong>Witam,</strong>
        <br /><br />
        W związku z utratą połączenia z licznikiem, proszę o wykonanie procedury resetowania licznika klientów, który znajduje
        się na wysokości wejścia do lokalu (pod sufitem):<br />
        <ul>
            <li>Odłącz urządzenie od zasilania na min. 2 minuty</li>
            <li>Podłącz urządzenie ponownie.</li>
        </ul>
        Proszę o potwierdzenie wykonania tej czynności w odpowiedzi na tą wiadomość email.<br /><br />
        
        Serwis iSensor<br />
        serwis@isensor.pl`
      ),
    });
  }

  sendEmail() {
    const controls = this.sendEmailForm.controls;
    /** check form */
    if (this.sendEmailForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    } else {
      const email = {
        // posComment: this.updatePosForm.value.comment,
        posIds: this.idApsArray,
      };
      this.posSensorsService.sendEmail(email).subscribe((res) => {
        this.activeModal.close('PosUpdate');
      });
    }
  }
  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.sendEmailForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  ngOnDestroy() {
    this.idApsArray = [];
  }
}
