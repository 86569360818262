// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .kt-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
:host ::ng-deep .kt-loading-bar .progress-bar {
  background-color: #3d4aed;
}
:host ::ng-deep .kt-header__topbar-item {
  height: 100%;
}
@media (min-width: 1025px) {
  :host ::ng-deep .kt-header__topbar, :host ::ng-deep .kt-header__topbar-item-wrapper {
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/header/header.component.scss"],"names":[],"mappings":"AAEE;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;AADH;AAGG;EACC,yBAAA;AADJ;AAKE;EACC,YAAA;AAHH;AAME;EACC;IACC,YAAA;EAJF;AACF","sourcesContent":[":host {\r\n\t::ng-deep {\r\n\t\t.kt-loading-bar {\r\n\t\t\tposition: absolute;\r\n\t\t\ttop: 0;\r\n\t\t\tleft: 0;\r\n\t\t\tright: 0;\r\n\t\t\twidth: 100%;\r\n\r\n\t\t\t.progress-bar {\r\n\t\t\t\tbackground-color: #3d4aed;\r\n\t\t\t}\r\n\t\t}\r\n\r\n\t\t.kt-header__topbar-item {\r\n\t\t\theight: 100%;\r\n\t\t}\r\n\r\n\t\t@media (min-width: 1025px) {\r\n\t\t\t.kt-header__topbar, .kt-header__topbar-item-wrapper {\r\n\t\t\t\theight: 100%;\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
