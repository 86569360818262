// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
// NgBootstrap
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
// Core module
import { CoreModule } from '../../core/core.module';
// Modal
import { UpdatePosComponent } from '../pages/pos/updatePos/pos.component';
import { SendEmailComponent } from '../pages/pos/sendEmail/sendEmail.component';

// Layout partials
import {
  ColumnVisibilityMenuComponent,
  ScrollTopComponent,
  SearchResultComponent,
  SplashScreenComponent,
  Subheader1Component,
  SubheaderSearchComponent,
  UserProfile3Component,
} from './layout';
// General
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [
    ScrollTopComponent,
    // topbar components
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    UserProfile3Component,
    ErrorComponent,
    ColumnVisibilityMenuComponent,
    UpdatePosComponent,
    SendEmailComponent,
  ],
  exports: [
    WidgetModule,
    PortletModule,
    ScrollTopComponent,
    ColumnVisibilityMenuComponent,
    // topbar components
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    UserProfile3Component,
    ErrorComponent,
  ],
  providers: [UpdatePosComponent, SendEmailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTooltipModule,
  ],
})
export class PartialsModule {}
