// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-input-element {
  background-color: transparent;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/auth/login/login.component.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,UAAA;AACD","sourcesContent":[".mat-mdc-input-element {\n\tbackground-color: transparent;\n\tpadding: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
