// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../../../partials/partials.module';
// Pages
import { CoreModule } from '../../../../core/core.module';
import { PosComponent } from './pos.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PortletModule } from '../../../partials/content/general/portlet/portlet.module';
// Services
import { TableService } from '../../../../services/table.service';
import { PosSensorsService } from '../../../../services/pos-sensors.service';
import { ToastService } from '../../../../services/toast.service';
import { MatTableExporterModule } from 'mat-table-exporter';

import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// Helpers
import { MatPaginatorIntlPl } from '../../../../helpers/polishLabelsInTable';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [PosComponent],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    PartialsModule,
    PortletModule,
    RouterModule.forChild([
      {
        path: '',
        component: PosComponent,
      },
    ]),
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatGridListModule,
    MatTableExporterModule,
    NgbModalModule,
  ],
  providers: [
    TableService,
    PosSensorsService,
    ToastService,
    NgbActiveModal,
    MatPaginatorIntlPl,
  ],
})
export class PosModule {}
