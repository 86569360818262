// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-splash-screen {
  background-color: #f2f3f8;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
:host .kt-splash-screen img {
  margin-left: calc(100vw - 100%);
  width: 90px;
  margin-bottom: 30px;
}
:host .kt-splash-screen span {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}
:host .kt-splash-screen ::ng-deep [role=progressbar] {
  margin-left: calc(100vw - 100%);
}
:host .kt-splash-screen ::ng-deep .mat-progress-spinner circle, :host .kt-splash-screen ::ng-deep .mat-spinner circle {
  stroke: #319DB5;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/layout/splash-screen/splash-screen.component.scss"],"names":[],"mappings":"AACC;EACC,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAAF;AAEE;EACC,+BAAA;EACA,WAAA;EACA,mBAAA;AAAH;AAGE;EACC,+BAAA;EACA,mBAAA;AADH;AAKG;EACC,+BAAA;AAHJ;AAKG;EAEC,eAAA;AAJJ","sourcesContent":[":host {\r\n\t.kt-splash-screen {\r\n\t\tbackground-color: #f2f3f8;\r\n\t\tposition: absolute;\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t\talign-items: center;\r\n\t\tjustify-content: center;\r\n\t\theight: 100%;\r\n\t\twidth: 100%;\r\n\t\tz-index: 1000;\r\n\r\n\t\timg {\r\n\t\t\tmargin-left: calc(100vw - 100%);\r\n\t\t\twidth: 90px;\r\n\t\t\tmargin-bottom: 30px;\r\n\t\t}\r\n\r\n\t\tspan {\r\n\t\t\tmargin-left: calc(100vw - 100%);\r\n\t\t\tmargin-bottom: 30px;\r\n\t\t}\r\n\r\n\t\t::ng-deep {\r\n\t\t\t[role=\"progressbar\"] {\r\n\t\t\t\tmargin-left: calc(100vw - 100%);\r\n\t\t\t}\r\n\t\t\t.mat-progress-spinner circle, .mat-spinner circle {\r\n\t\t\t\t// brand color\r\n\t\t\t\tstroke: #319DB5;\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
