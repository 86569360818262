// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-header-menu-wrapper {
  height: 100%;
}

.mat-bage-top-20px {
  top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/header/menu-horizontal/menu-horizontal.component.scss"],"names":[],"mappings":"AACC;EACC,YAAA;AAAF;;AAGA;EACC,SAAA;AAAD","sourcesContent":[":host {\r\n\t.kt-header-menu-wrapper {\r\n\t\theight: 100%;\r\n\t}\r\n}\r\n.mat-bage-top-20px {\r\n\ttop: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
