// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  FirstLetterPipe,
  ScrollTopDirective,
  StickyDirective,
  TabClickEventDirective,
  ToggleDirective,
  ShowHidePasswordDirective,
} from './_base/layout';

import { InterceptService } from '../core/_base/crud';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    ShowHidePasswordDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    ShowHidePasswordDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class CoreModule {}
