import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

// https://github.com/auth0/angular2-jwt#usage-standalone
// Na potrzeby dekodowania tokena
import { JwtHelperService } from '@auth0/angular-jwt';
const jwtHelper = new JwtHelperService();

const API_USERS_URL = 'api/users';

@Injectable()
export class AuthService {
  url = environment.url;
  constructor(private http: HttpClient) {}

  /**
   * Odpowiada za logowanie użytkownika. W procesie logowania generowany jest token JWT
   *
   * @param email
   * @param password
   */
  login(email: string, password: string): Observable<User> {
    return this.http.post<User>(`${this.url}/user/login`, { email, password });
  }

  /**
   * Zwraca dane użytkownika zapisane w tokenie JWT
   */
  getUserByToken(): Observable<User> {
    // Zamiast pobierać dane usera z serwera, dekoduję token i wyciągam dane bezpośrednio z niego
    const token = jwtHelper.decodeToken(
      localStorage.getItem(environment.authTokenKey)
    );
    return of(token.data);
    /*
    const userToken = localStorage.getItem(environment.authTokenKey);
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Authorization', 'Bearer ' + userToken);
    return this.http.get<User>(API_USERS_URL, { headers: httpHeaders });
    */
  }
  /**
   * Deautoryzacja tokena na serwerze
   */
  deauthorizeJwtToken(): Observable<any> {
    return this.http.post<any>(`${this.url}/user/deauthorize`, {});
  }
  /**
   * Zwraca role przypisane do uzytkownika
   */
  getRole(): Observable<any> {
    return this.getUserByToken().pipe(
      map((res) => {
        return res.roles;
      })
    );
  }

  register(user: User): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<User>(API_USERS_URL, user, { headers: httpHeaders })
      .pipe(
        map((res: User) => {
          return res;
        }),
        catchError((err) => {
          return null;
        })
      );
  }

  // 1. Wysłanie żądania resetowania hasła
  requestPasswordReset(email) {
    return this.http.post<any>(
      `${this.url}/user/request-password-reset`,
      email
    );
  }
  // 2. Na potrzeby walidacji tokena
  validatePasswordResetToken(token) {
    return this.http.post<any>(
      `${this.url}/user/validate-password-reset-token`,
      token
    );
  }
  // 3. Przesłanie hasła i tokena
  resetPassword(credentials) {
    return this.http.post<any>(`${this.url}/user/reset-password`, credentials);
  }

  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.http
      .get(API_USERS_URL + '/forgot?=' + email)
      .pipe(catchError(this.handleError('forgot-password', [])));
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(API_USERS_URL);
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(API_USERS_URL + `/${userId}`);
  }

  // DELETE => delete the user from the server
  deleteUser(userId: number) {
    const url = `${API_USERS_URL}/${userId}`;
    return this.http.delete(url);
  }

  // UPDATE => PUT: update the user on the server
  updateUser(_user: User): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User): Observable<User> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      API_USERS_URL + '/findUsers',
      queryParams,
      {
        headers: httpHeaders,
      }
    );
  }
  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
